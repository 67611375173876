import React from "react"
import { Layout, Navigation, QuoteForm } from "../components"

export const Quote = () => {
  return (
    <Layout title="Get a Quote">
      <Navigation />
      <QuoteForm />
    </Layout>
  )
}

export default Quote
